<template>
  <div id="app" class="text-right">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: "App",
    data() {
      return {
      };
    },
    created() {
    },

    methods: {
    },
    mounted() {
      // 在页面挂载时引入字体文件
      const cssLink = document.createElement('link')
      cssLink.rel = 'stylesheet'
      cssLink.href = 'https://fonts.googleapis.com/css?family=Cairo:bold,200,300,400,500,600,700,800,900|Poppins:bold,200,300,400,500,600,700,800,900|Montserrat:bold,200,300,400,500,600,700,800,900|Mada:bold,200,300,400,500,600,700,800,900'
      document.getElementsByTagName('head')[0].appendChild(cssLink)
    },
  };
</script>
<style >
  html[lang="ar"] .text-right {
    text-align: right;
  }

  html[lang="zh"] .text-left {
    text-align: left;
  }

  html
   {
    /* padding: 0 16px 0 16px; */
  }

  .norecord {
  position: absolute;
  top: 40%;
  bottom: 50%;
  left: 0;
  right: 0;
}

.noTransfer {
  color: #000000;
  font-size: 0.22rem;
  font-family: Regular;
}
.showEllipsis{
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

</style>