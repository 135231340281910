export default{
    "xmas_title": "Feliz Natal e Ano Novo",
    "roomcup_title": "",
    "roomcup_Room ID ": "",
    "roomcup_countdown": "",
    "roomcup_day": "",
    "roomcup_hour": "",
    "roomcup_minutes": "",
    "roomcup_seconds": "",
    "roomcup_coin_recieve": "",
    "roomcup_target_icon": "",
    "roomcup_reward_icon": "",
    "roomcup_roomreward": "",
    "roomcup_roomreward_lasttarget": "",
    "roomcup_roomreward_lastreward": "",
    "roomcup_claim_icon": "",
    "roomcup_claimed_icon": "",
    "roomcup_doubletargetcontest": "",
    "roomcup_ratio": "",
    "roomcup_doubletarget": "",
    "roomcup_doubletarget_reward": "",
    "roomcup_noroom": "",
    "roomcup_noarea": "",
    "roomcup_rule_icon": "",
    "roomcup_rule_row1": "",
    "roomcup_rule_row2": "",
    "roomcup_rule_row3": "",
    "roomcup_rule_row4": "",
    "roomcup_coins": "",
    "roomcup_diamonds": "",
    "roomcup_notachieved": "",
    "roomcup_successful": "",
    "budang_title": "",
    "budang_beforestart": "",
    "budang_inprogress": "",
    "budang_ended": "",
    "budang_days": "",
    "budang_hours": "",
    "budang_minutes ": "",
    "budang_seconds": "",
    "budang_open": "",
    "budang_open_cannot": "",
    "budang_open_once": "",
    "budang_open_ended": "",
    "budang_myagency": "",
    "budang_mytarget": "",
    "budang_distanceaway": "",
    "budang_distanceaway_2": "",
    "budang_highestlevel": "",
    "budang_rewardname": "",
    "budang_reward1": "",
    "budang_reward2": "",
    "budang_reward3": "",
    "budang_reward_rule": "",
    "budang_rule_button": "",
    "budang_rule_row1": "",
    "budang_rule_row2": "",
    "budang_rule_row3": "",
    "budang_rule_row4": "",
    "resellerNotTransaction": "Este usuário está fora do seu alcance de negociação, portanto, a transação não pode ser concluída.",
    "tingzhan_title": "Party Glory",
    "tingzhan_time_title": "Event Time",
    "tingzhan_time": "UTC+3 11.25 19:00 - 12.4 24:00",
    "tingzhan_rule_buttom": "Rules & Rewards",
    "tingzhan_tab1": "Qualifying ",
    "tingzhan_tab1_time": "11.25-11.28",
    "tingzhan_tab1_rule_row1": "Best 16",
    "tingzhan_tab1_rule_row2": "The ranking based on the cumulative number of coins sent of the event gifts in the room, the number of coins is the same, the first to arrive first.",
    "tingzhan_tab1_rule_row3": "In this round, TOP1-8 rooms enter the gold group, and 9-16 rooms enter the silver group.",
    "tingzhan_tab1_rule_row4": "UTC+3 11.25 19:00 - 11.28 18:59",
    "tingzhan_tab_gold": "Gold Group",
    "tingzhan_tab_silver": "Silver Group",
    "tingzhan_tab2": "Promotion ",
    "tingzhan_tab2_time": "11.28-12.1",
    "tingzhan_tab2_goldrule_row1": "Best 6",
    "tingzhan_tab2_goldrule_row2": "The ranking based on the cumulative number of coins sent of the event gifts in the room, the number of coins is the same, the first to arrive first. The 7th and 8th place rooms will fall into the silver group.",
    "tingzhan_tab1_goldrule_row3": "UTC+3 11.28 19:00 - 12.01 18:59",
    "tingzhan_tab2_silverrule_row1": "Best 4",
    "tingzhan_tab2_silverrule_row2": "The ranking based on the cumulative number of coins sent of the event gifts in the room, the number of coins is the same, the first to arrive first. Rooms ranked 5-8 will be eliminated",
    "tingzhan_tab2_silverrule_row3": "UTC+3 11.28 19:00 - 12.01 18:59",
    "tingzhan_tab3": "PK ",
    "tingzhan_tab3_time": "12.1-12.4",
    "tingzhan_tab3_goldrule_row1": "Best 3-PK",
    "tingzhan_tab3_goldrule_row2": "1v1 PK with accumulated coins for 3 days. The winner advances, the loser advances to the silver group.",
    "tingzhan_tab3_goldrule_row3": "There is a quota for resurrection in this round, and the loser with the most coins will be resurrected.",
    "tingzhan_tab3_goldrule_row4": "UTC+3 12.01 19:00 - 12.04 18:59",
    "tingzhan_tab3_silverrule_row1": "Best 3-PK",
    "tingzhan_tab3_silverrule_row2": "1v1PK with accumulated coins for 3 days. The loser will be eliminated, the number of coins is the same, the first to arrive first.",
    "tingzhan_tab3_silverrule_row3": "UTC+3 12.01 19:00 - 12.04 18:59",
    "tingzhan_tab4": "Final ",
    "tingzhan_tab4_time": "12.4-12.5",
    "tingzhan_tab4_rule_row1": "Final",
    "tingzhan_tab4_rule_row2": "The ranking based on the cumulative number of coins sent of the event gifts in the room, the number of coins is the same, the first to arrive first.",
    "tingzhan_tab4_rule_row3": "UTC+3 12.04 19:00 - 12.05 18:59",
    "tingzhan_tab4_countdown": "Final Countdown",
    "tingzhan_danger": "Danger",
    "tingzhan_win": "Win",
    "tingzhan_out": "Out",
    "tingzhan_Revive": "Revive",
    "tingzhan_Silver": "Silver",
    "tingzhan_rule_back": "Back",
    "tingzhan_rule_tab1": "Event Intro",
    "tingzhan_rule_tab2": "Rewards",
    "tingzhan_rule_tab1_title": "Event Gifts",
    "tingzhan_rule_luvkygift1": "?",
    "tingzhan_rule_gift2": "Gatinho da Rocha",
    "tingzhan_rule_gift3": "Falconista",
    "tingzhan_rule_gift4": "Mapa do Tesouro",
    "tingzhan_rule_gift4_name": "Sponsorship (TOP1) * 10 days",
    "tingzhan_gift_price": "Price",
    "tingzhan_tab2_banner_title": "1.5 Times Exp.!",
    "tingzhan_tab2_banner_body": "Sending event gifts during the event and you will get 1.5 Times experience points.",
    "tingzhan_tab2_roomreward": "Room Rewards",
    "tingzhan_tab2_supporterreward": "Supporter Rewards",
    "tingzhan_tab2_reward": "Rewards",
    "tingzhan_tab2_roombg": "Moving Bg",
    "tingzhan_roomcover": "Room Cover",
    "tingzhan_1stplace": "1st Place",
    "tingzhan_2ndplace": "2nd Place",
    "tingzhan_3rdplace": "3rd Place",
    "tingzhan_tab2_usd": "USD",
    "tingzhan_500usd": "500 USD",
    "tingzhan_400usd": "400 USD",
    "tingzhan_300usd": "300 USD",
    "tingzhan_200usd": "200 USD",
    "tingzhan_100usd": "100 USD",
    "tingzhan_tab2_avatar": "Avatar Frame",
    "tingzhan_tab2_entrance": "Entrance Effect",
    "tingzhan_tab2_specialid": "Special ID",
    "tingzhan_tab2_sponsorship": "Event Gift Sponsorship",
    "tingzhan_supporter_1stplace": "1st Place Room Supporter",
    "tingzhan_supporter_2ndplace": "2nd Place Room Supporters",
    "tingzhan_supporter_3rdplace": "3rd Place Room Supporters",
    "tingzhan_30days": "30 days",
    "tingzhan_15days": "15 days",
    "tingzhan_7days": "7 days",
    "tingzhan_3days": "3 days",
    "tingzhan_tab2_specialid_body1": "Random 2/3/4 -digital ID",
    "tingzhan_tab2_specialid_body2": "Special ID: 50/500/5000",
    "tingzhan_tab2_sponsorship_body": "Sponsorship (TOP1) * 10 days",
    "tingzhan_tab2_other": "Aside from experience points, event rewards will be sent in 3 working days to the user's backpack.",
    "tingzhan_notstart": "Event hasn't started",
    "turkeyCumhuriyet": "Cumhuriyet Bayramı Event",
    "turkeyDistance": "Event starts in",
    "turkeyStart": "Event ends in",
    "turkeyEnd": "Event ended",
    "turkeyEventGifts": "Event Gifts",
    "turkeyCumhuriyetGift": "Cumhuriyet Bayramı",
    "turkey80": "80k coins",
    "turkeyDays": "days",
    "turkeyHours": "hours",
    "turkeyMin": "min",
    "turkeyToStart": "to Start",
    "turkeyTurkey": "Turkey",
    "turkeyTop1": "Top 1 ",
    "turkey500": 500,
    "turkeyTop2_3": "Top2-3 ",
    "turkey300": "300k diamonds",
    "turkeyTop4_10": "Top4-10",
    "turkey100": " 100k diamonds",
    "turkey200": 200,
    "turkey200k": "200k coins",
    "turkeyTop4_10_2": "Top 20 rooms",
    "turkeySupporters": "Supporters",
    "turkeyAnchors": "Anchors",
    "turkeyParties": "Parties",
    "turkeyRanking": "Ranking countries: Turkey & Azerbaijan",
    "turkeyRanking2": "Ranking countries: Non - Turkey or Azerbaijan",
    "turkeytop1newanchor": "200k coins",
    "turkeytop2-3newanchor": "50k coins",
    "turkeytop4-10newanchor": "20k coins",
    "turkeytop1newsupport": "Noble 6*30 days",
    "turkeytop2-3newsupport": "Noble 5*30 days",
    "turkeytop4-10newsupport": "Noble 4*30 days",
    "wealthNeedNextLevel": "A experiência pode ser atualizada para o próximo nível",
    "pkTouramentPro": "PK Tourament Pro",
    "pkTop1": "Top1",
    "pkTop2": "Top2",
    "pkTop3": "Top3",
    "pk1000Usd": "1000 USD",
    "pk500Usd": "500 USD",
    "pk300Usd": "300 USD",
    "pkRewards": "Rewards for each Qualifying Round",
    "pkRewards2": "Rewards for each Promotion Round",
    "pk50kCoins": "50k Coins Target",
    "pk100kCoins": "100k Coins Target",
    "pkNote": "Note: for qualifying round and promotion round rewards, if the diamonds spent in the specific PK is less than the reward amount. The reward will not be sent but the PK result is still counted",
    "pk200Usd": "200 USD",
    "pk30Day": "Frame*30 Days",
    "pk15Day": "Frame*15 Days",
    "pk7Day": "Frame*7 Days",
    "pkNote2": "Note：Support in all PK Rounds is calculated. Lucky gifts are counted 10% Treasure Boxes are counted based on the gift won amount. ",
    "pkTourament": "Tourament",
    "pkToday": "Today Schedule",
    "pkSupporter": "Supporter",
    "pkQualifying": "Qualifying Round",
    "pkQualifying1": "Qualifying Round1",
    "pkQualifying2": "Qualifying Round2",
    "pkPromotion": "Promotion Round",
    "pkSummit": "Summit Series",
    "pk32Finals": "1/32-Finals",
    "pk16Finals": "1/16-Finals",
    "pk8Finals": "1/8-Finals",
    "pkQuater": "Quater-Finals",
    "pkSemi": "Semi-Finals",
    "pkFinal": "Final",
    "pk3rd": "3rd Place",
    "pk_host_reward_top1": "",
    "pk_host_reward_top1_b": "",
    "pk_host_reward_top2": "",
    "pk_host_reward_top2_b": "",
    "pk_host_reward_top3": "",
    "pk_host_reward_top3_b": "",
    "pk_supporter_reward_top1": "",
    "pk_supporter_reward_top1_b": "",
    "pk_supporter_reward_top2": "",
    "pk_supporter_reward_top2_b": "",
    "pk_supporter_reward_top3": "",
    "pk_supporter_reward_top3_b": "",
    "pk_host_rule_row1": "",
    "pk_host_rule_row2": "",
    "pk_host_rule_note": "",
    "pk_supporter_rule_note": "",
    "dreamMidsummer": "Midsummer Night's Dream",
    "dreamSend": "Send exclusive gifts to seal your midsummer love",
    "dreamRank": "Sending the following event gifts during the event. The higher the ranking, the richer the prizes. Come and participate to get generous rewards!",
    "dreamPreview": "Rewards Preview",
    "dreamSupporter": "Supporter",
    "dreamHost": "Host",
    "dreamTop1": "Top1",
    "dreamTop2": "Top2",
    "dreamTop3": "Top3",
    "dreamTop4_10": "Top4-10",
    "dreamRankings": "Rankings",
    "dreamRose": "Rose Throne",
    "dreamRoseBasket": "Rose Basket",
    "dreamButterfly": "Butterfly Wonderland",
    "dreamLovers": "Everlasting Love",
    "dreamFlower": "How Much Love",
    "dream15d": "*15d",
    "dreamCentury": "Glorious Century",
    "dream7": "*7",
    "dream7d": "*7d",
    "dreamHeart": "Heart Flying",
    "dreamSummerLove": "Summer Love",
    "dreamFancy": "Fancy Unicorn",
    "dreamRules": "Event Rules",
    "dreamTime": "Event time",
    "dreamPlay": "How to play",
    "dreamUsersTop": "During the event, via sending specific gifts, users are ranked based on the number of diamonds given and coins received. Users with top rankings can receive rewards.",
    "dreamRewards": "Rewards for Rose Basket and Butterfly Wonderland Ranking",
    "dreamFrame": "Rose Throne Frame",
    "dreamCenturyGift": "Glorious Century Gift",
    "dreamEntrance": "Heart Flying Entrance",
    "dream5": "*5",
    "dream5d": "*5d",
    "dream3": "*3",
    "dream3d": "*3d",
    "dreamDiamonds": "Diamonds",
    "dream25000": "*25000",
    "dreamVerification": "Summer Love Verification",
    "dreamUnicorn": "Fancy Unicorn",
    "dreamEverlasting": "Rewards for Everlasting Love and How Much Love Rank",
    "dreamCoins": "Coins",
    "dream100000": "*100000",
    "dream50000": "*50000",
    "dream5000": "*5000",
    "dreamHostBottom": "Coins counted in the target",
    "zainCashTitle": "Zain Cash",
    "zainTotalPay": "Pagamento Total",
    "zainIqd": "IQD",
    "zainName": "Nome",
    "zainNameTips": "Insira seu nome conforme o RG",
    "zainPhoneNumber": "Número de Telefone",
    "zainPhoneNumberTips": "Insira seu número de telefone",
    "zainSubmit": "Pagar",
    "zainNumberErrorTips": "Por favor, insira um número ou sinal de +",
    "zainNameNotNull": "O nome não pode estar vazio",
    "luckyEventStartsIn": "Evento começa em",
    "luckyEventEndsIn": "",
    "luckyEventInstruction": "Instruções do evento",
    "luckySendingFollowing": "Enviando o seguinte presente pode obter uma ovelhinha",
    "luckyRoom": "Sala",
    "luckyRoomTips": "",
    "luckyGifter": "Doador",
    "luckyAnchors": "",
    "luckyGoldenBoot": "",
    "luckyFootball": "",
    "luckyDefensiveMaster": "",
    "luckyTheWorldCup": "",
    "luckyNoOne": "Ninguém na lista ainda~",
    "luckyRules": "Regras",
    "luckyHowTo": "Como entrar na classificação?",
    "luckyHowToGetI": "Como obter",
    "luckyTheGifts": "Os presentes dados na atividade são classificados de acordo com o número acumulado de diamantes, os presentes recebidos do evento são classificados de acordo com o número acumulado de moedas de ouro.",
    "luckyRewards": "Recompensas",
    "luckyRoomReward": "Recompensa da sala",
    "luckyGifterRewards": "Recompensas do doador",
    "luckyBlessingCard": "Cartão de bênção",
    "luckyTop_1": "Créditos 100000",
    "luckyTop_2": "Moldura de avatar x<span>10<span>dias",
    "luckyTop_3": "Entrada x10dias",
    "luckyTop_1_1": "",
    "luckyTop_2_1": "",
    "luckyTop_3_1": "",
    "luckyTop_4": "Bandeira Exclusiva",
    "luckyEventEnd": "Fim do Evento",
    "anchorActive": {
        "activeList": "Atividade",
        "activeListRewards": "Ranking de Atividade",
        "back": "Voltar",
        "banner10": "Banner Personalizado (10 dias)",
        "capture": "Seja New Star；Ganhe Recompensas",
        "charming": "Ranking de Charme",
        "charmingList": "Charme",
        "days": "Dias",
        "entrance10": "Entrada Exclusiva (10 dias)",
        "entrance5": "Entrada Exclusiva (5 dias)",
        "entrance7": "Entrada Exclusiva (7 dias)",
        "frame10": "Moldura de avatar exclusiva(10 dias)",
        "frame5": "Moldura de avatar exclusiva (5 dias)",
        "frame7": "Moldura de avatar exclusiva (7 dias)",
        "hours": "Horas",
        "laseWeek": "Semana Passada",
        "laseWeekRank": "Semana Passada",
        "listOne": "Ranking de Atividade",
        "listOneMsg1": "Renda de pontos obtida através de 1v1 = valor da atividade correspondente (Incluindo ganho de resposta, chamadas de áudio e vídeo, etc., todos os pontos, exceto recebimento de presentes)",
        "listOneMsg2": "Tempo/minuto do microfone da sala de áudio = 10 pontos",
        "listTwo": "Ranking de Charme",
        "listTwoMsg1": "Quntidade de diamantes de presentes recebidos em 1v1 = valor de Charme correspondente",
        "listTwoMsg2": "Quntidade de diamantes recebidos como presente na sala de voz = o dobro do valor de Charme",
        "listTwoMsg3": "*Presente da sorte com 10% de desconto no valor de Charme",
        "minutes": "Minutos",
        "noRanking": "Não há dados de classificação disponíveis.",
        "points100000": "10k pontos",
        "points30k": "30k pontos",
        "points50000": "5k pontos",
        "points50k": "50k pontos",
        "rank": "Ranking",
        "rewards": "Recompensa ",
        "rules": "Regras",
        "seconds": "Segundos",
        "top1": "TOP1",
        "top2": "TOP2",
        "top3": "TOP3"
    },
    "bindAccount": {
        "Bank_Transfer": "Transferência Bancária",
        "Bind_Account": "Vincular Conta",
        "limit": {
            "account_name_tips": "Digite o Nome Completo em Inglês",
            "bank_card_number": "Digite o Número da Conta Bancária, por exemplo: 10000000000",
            "bankCode": "Digite o Código Swift do Banco, por exemplo: CMBCCNBS",
            "bankName": "Digite o Nome do Banco em Inglês, por exemplo: State Bank of India",
            "City": "Digite sua Cidade em Inglês",
            "country_code": "Digite o Código do País em Maiúsculas, por exemplo: IN",
            "name_tips": "Digite o Primeiro Nome em Inglês",
            "payoneer_email": "Encontre o Email Vinculado à sua Conta Payoneer",
            "payoneerID": "Encontre seu ID do Payoneer em sua Conta Payoneer, por exemplo: 47289645466",
            "paypal_email": "Digite o Email Vinculado à sua Conta do PayPal",
            "State_or_Province": "Digite o Estado ou Província em Inglês",
            "Street": "Digite a Rua em Inglês",
            "StrePostal_Codeet": "Digite o Código Postal do Endereço"
        },
        "Payment_Method": "Método de Pagamento",
        "placeholder": {
            "bank_account_name": "Nome da Conta Bancária do Beneficiário",
            "bank_card_number": "Número da Conta Bancária do Beneficiário",
            "bankCode": "Código do Banco do Beneficiário",
            "bankName": "Nome do Banco do Beneficiário em Inglês",
            "Beneficiary_country_code": "Código do País do Beneficiário",
            "City": "Endereço do Beneficiário - Cidade",
            "country": "Código do País do Cartão Bancário",
            "Payee_First_name": "Primeiro Nome do Beneficiário",
            "Payee_Last_Name": "Sobrenome do Beneficiário",
            "payoneer_email": "Insira seu e-mail",
            "payoneerID": "Insira seu ID do Payoneer",
            "paypal_email": "Insira o e-mail da sua conta",
            "Postal_Code": "Endereço do Destinatário - CEP",
            "State_or_Province": "Endereço do Destinatário - Estado ou Província",
            "Street": "Rua"
        },
        "popup": {
            "Bound_account": "Vincular Conta:",
            "cancel": "Cancelar",
            "complete": "Concluído",
            "confirm": "Confirmar",
            "country": "País do Cartão Bancário",
            "payment_method": "Método de Pagamento",
            "payoneer_email": "E-mail da Conta Payoneer"
        },
        "title": {
            "bank_account_name": "Nome da Conta Bancária do Beneficiário",
            "bank_card_number": "Conta Bancária do Beneficiário",
            "bankCode": "Código do País do Cartão Bancário",
            "bankName": "Nome do Banco do Beneficiário",
            "country": "País do Cartão Bancário",
            "Payee_Address": "Endereço do Beneficiário",
            "Payee_First_name": "Primeiro Nome do Beneficiário",
            "Payee_Last_Name": "Sobrenome do Beneficiário",
            "payoneer_email": "E-mail da conta do Payoneer",
            "payoneerID": "ID do Payoneer",
            "paypal_email": "E-mail da conta PayPal"
        },
        "toast": {
            "bank_account_name": "Nome da Conta Bancária do Destinatário não pode estar vazio",
            "bank_card_number": "Número da Conta Bancária do Destinatário não pode estar vazio",
            "bankCode": "Código do Banco do Destinatário não pode estar vazio",
            "bankName": "Nome do Banco do Destinatário não pode estar vazio",
            "Beneficiary_country_code": "O País do Destinatário não pode estar vazio",
            "City": "A Cidade do Destinatário não pode estar vazia",
            "country": "O País do Cartão Bancário não pode estar vazio",
            "emailyz": "Por favor, insira um endereço de e-mail válido e legal",
            "Payee_First_name": "O Primeiro Nome do Destinatário não pode estar vazio",
            "Payee_Last_Name": "O Sobrenome do Destinatário não pode estar vazio",
            "payoneer_account": "Por favor, verifique novamente para evitar qualquer perda financeira",
            "payoneer_email": "O E-mail da Payoneer não pode estar vazio",
            "payoneerID": "A ID da Payoneer não pode estar vazia",
            "paypal_email": "O E-mail da Payoneer não pode estar vazio",
            "State_or_Province": "O Estado ou Província do Destinatário não pode estar vazio",
            "Street": "O Endereço do Destinatário não pode estar vazio"
        }
    },
    "diamondDescription": {
        "title": "Regras VIP e SVIP",
        "txt1": "1. VIP e SVIP podem ser ativados simultaneamente.",
        "txt2": "2. Os usuários VIP podem receber vouchers de mensagem gratuitos diariamente.",
        "txt3": "3. Os usuários SVIP podem receber diamantes gratuitos diariamente.",
        "txt4": "4. Os usuários SVIP podem completar tarefas de reembolso de diamantes diariamente.",
        "txt5": "5. As tarefas de reembolso de diamantes SVIP precisam ser reivindicadas no mesmo dia em que são atualizadas. Os vouchers de mensagem diários VIP também devem ser reivindicados no mesmo dia; eles não acumulam se não forem reivindicados e expiram.",
        "txt6": "6. Os gastos no jogo não serão contados como parte da quantidade de diamantes consumidos."
    },
    "eventRewards": {
        "player": "Número de jogadores ativos diários",
        "proportion": "Percentagem a receber se a sala ganhar",
        "title": "Retorno de Atividade",
        "txt1": "1. Calcular os dados do dia anterior às 00:00 (UTC+8) diariamente.",
        "txt2": "2. Distribuir recompensas com base no número de jogadores ativos e no consumo real de diamantes na sala (diamantes apostados pelos usuários - diamantes ganhos pelos usuários).",
        "txt3": "3. Calcular os dados semanais toda segunda-feira às 00:00 (UTC+8), com as recompensas a serem emitidas em até 3 dias.",
        "txt4": "4. Proporção de distribuição de recompensas:",
        "txt5": "5. Definição de jogador ativo: um jogador que apostou mais de 1.000 diamantes no total naquele dia."
    },
    "failed": "操作失败！",
    "idolHelp": {
        "content1": "Layla Idol é um selo especial concedida para as garotas mais populares, e isso sognifica vários privilégios, incluindo um selo especial, mais exposição e mais mensagens.",
        "content2_1": "1. Primeiro, você precisa passar pela verificação de pessoa real. Em segundo lugar, sua pontuação no chat deve atingir 80 pontos.",
        "content2_2": "2. Caso atendem aos requisitos de atividade do dia, a pontuação no chat será calculada às 4 da manhã (UTC+8) no dia seguinte, com base nos dados dos últimos 7 dias.",
        "content2_3": "3. Se os requisitos de atividade não forem atendidos no dia, a pontuação no chat zera no dia seguinte. Tornar-se ativo novamente reiniciará o acúmulo e o cálculo da pontuação no chat.",
        "content2_4": "4. Os requisitos diários de atividade são os seguintes:",
        "content2_4_1": "a.Mais de",
        "content2_4_2": "b.Mais de ",
        "content2_4_3": "c.Faz o login ao App no dia anterior.",
        "content3_1": "A pontuação de chat é calculada com base na sua popularidade no chat privado que inclue:",
        "content3_2": "1. Exposição: quantas pessoas te viram na página inicial, na página de Momento ou no seu perfil.",
        "content3_3": "2. Duração online: quanto tempo você ficar online no App.",
        "content3_4": "3. Número de intimidade ativa: quantos usuários com uma intimidade maior que 3 lhe enviam mensagens no dia.",
        "content3_5": "4. Taxa de resposta às mensagens: quantas mensagens que manda e quantas respostas que recebe.",
        "content3_6": "5. Número de pessoas com quem iniciou chats: quantos usuários você cumprimenta ou envia mensagens.",
        "content3_7": "6. Taxa de resposta efetiva: A taxa que você responde às mensagens de outros dentro de um tempo válido.",
        "content3_8": "7. Consumo médio de diamantes no chat: quantos diamantes as pessoas gastam conversando com você, incluindo chat e presentes 1v1.",
        "content3_9": "8. Número de pessoas que gastaram diamantes na conversa: quantas pessoas gastaram diamantes enquanto conversavam com você, incluindo as mensagens e presentes no Chat.",
        "content4_1": "Você pode ver os valores estatísticos dos seus diversos dados e compará-los com os das Layla Idol na página de detalhes da pontuação de chat. ",
        "content4_2": "Pode ver as dicas através de clicar nos itens correspondentes, seguir a orientação para melhorar sua pontuação.",
        "header1": "O que é Layla Idol？",
        "header2": "Como Tornar-se uma Layla Idol？",
        "header3": "O que é a Pontuação de Chat？",
        "header4": "Como Melhorar Sua Pontuação de Chat",
        "minute": "minutos de tempo online nos últimos 7 dias.",
        "people": "pessoas com as quais conversou ativamente nos últimos 7 dias.",
        "title": "Ajuda do Layla Idol"
    },
    "inviteCash": {
        "COPY": "Copiar",
        "Copy_successfully": "Cópia bem sucedida",
        "title": "Convidar para ganhar dinheiro"
    },
    "Level": {
        "avatarFrame": "Moldura de avatar",
        "badgeReward": "Prêmios de Medalha",
        "charmLevel": "Nível de Carisma",
        "currentExp": "Valor da experiência atual",
        "entranceEffect": "Carro de entrada",
        "familyLevel": "Ranking de Família",
        "familyLeveldata": [
            "Níveis",
            "Valor da contribuição necessária",
            "Número de membros",
            "Identidade"
        ],
        "gradePrivileges": "Privilégios de Níveis",
        "identity": [
            "Líder adjunto da família",
            "Elder"
        ],
        "personLevel": "Nível Pessoal",
        "stillNeed": "precisando",
        "title": "Níveis",
        "upgrade": "Como faço para subir de nível?",
        "upgradebox": {
            "charm": [
                {
                    "title": "Receber presentes",
                    "value": " 1 moneda de oro = 1 valor de carisma"
                }
            ],
            "family": [
                {
                    "title": "Tarefas Familiares",
                    "value": "Resgatar a contribuição da família"
                }
            ],
            "person": [
                {
                    "title": "Enviar presentes",
                    "value": "1 diamante = 1 experiência pessoal"
                },
                {
                    "title": "Completar a tarefa",
                    "value": "Resgatar experiência pessoal"
                }
            ],
            "wealth": [
                {
                    "title": "Enviar presentes",
                    "value": "1 elmas = 1 zenginlik deneyimi"
                },
                {
                    "title": "Completar a tarefa",
                    "value": "Resgatar experiência de riqueza"
                }
            ]
        },
        "wealthLevel": "Nível de Riqueza"
    },
    "nation": {
        "ae": "Emirados Árabes Unidos",
        "ao": "Angola",
        "ar": "Argentina",
        "at": "Áustria",
        "au": "Austrália",
        "bd": "Bangladesh",
        "bh": "Bahrein",
        "br": "Brasil",
        "ca": "Canadá",
        "cl": "Chile",
        "cn": "China Continental",
        "de": "Alemanha",
        "dz": "Argélia",
        "eg": "Egito",
        "es": "Espanha",
        "fr": "França",
        "gb": "Inglaterra",
        "hk": "Hong Kong, China",
        "il": "Israel",
        "iq": "Iraque",
        "ir": "Irã",
        "it": "Itália",
        "jo": "Jordânia",
        "jp": "Japão",
        "kw": "Kuwait",
        "lb": "Líbano",
        "lv": "Letônia",
        "ly": "Líbia",
        "ma": "Marrocos",
        "mx": "México",
        "my": "Malásia",
        "nl": "Países Baixos",
        "om": "Omã",
        "ph": "Filipinas",
        "pk": "Paquistão",
        "ps": "Palestina",
        "pt": "Portugal",
        "qa": "Qatar",
        "ru": "Rússia",
        "sa": "Arábia Saudita",
        "sd": "Sudão",
        "sy": "Síria",
        "tn": "Tunísia",
        "tr": "Turquia",
        "tw": "Taiwan, China",
        "us": "Estados Unidos",
        "ye": "Iêmen"
    },
    "nobilityLevel": {
        "diamond": "Consumo de Diamantes",
        "head": "Nível Nobre",
        "title": "Regras de Classificação Nobre",
        "txt1": "1. A partir das 0:00 do dia 1º de cada mês natural (UTC+8), o sistema calculará automaticamente os pontos Exp. do usuário e corresponderá novamente ao nível nobre do usuário de acordo com os pontos Exp. na tabela a seguir.",
        "txt2": "2. Cada diamante recarregado = 1 ponto Exp.",
        "txt3": "3. Cálculo dos pontos Exp. = Pontos Exp. do mês atual + Pontos Exp. do mês passado.",
        "txt4": "I. Após atingir a classificação nobre correspondente, a classificação nobre e os privilégios serão mantidos até o final do próximo mês. Por exemplo, se um usuário atingir o nível 4 em 17 de outubro, os privilégios do nível 4 continuarão até 30 de novembro.",
        "txt5": "II. Dentro do período do mês natural atual, se um usuário alcançar uma classificação nobre mais alta após obter uma mais baixa, eles receberão diretamente a classificação nobre mais alta, e a classificação correspondente será mantida até o final do próximo mês. Por exemplo, se um usuário atingir o nível 2 em 17 de outubro e depois atingir o nível 4 em 19 de outubro, eles receberão diretamente os privilégios do nível 4, que durarão até 30 de novembro.",
        "txt6": "III. Após o término do mês natural atual, o nível VIP pode manter o que foi alcançado no mês anterior, mas a acumulação de pontos VIP começará do zero no mês seguinte. Por exemplo, se um usuário alcançar o nível 4 em outubro, manter o nível 4 em novembro e atingir apenas o nível 2 até o final de dezembro, eles desfrutarão apenas dos privilégios do nível 2 a partir de 1º de dezembro.",
        "txt7": "Nível",
        "txt8": "Pontos de Exp. necessários",
        "txt9": "Pontos de Exp. necessários do nível anterior para este nível"
    },
    "patricianDescription": [
        {
            "title": "1. O que é a Nobre",
            "vlaue": [
                "A Nobre é um símbolo do prestígio dos usuários na plataforma e está atualmente dividido em seis níveis",
                "Diferentes níveis de Nobreza têm privilégios correspondentes e podem ser ativados pagando uma taxa mensal para obter e usufruir dos privilégios."
            ]
        },
        {
            "title": "2. Regras de compra",
            "vlaue": [
                "O acesso aos privilégios dos Nobres 1, 2 e 3 pode ser ativado através de pagamento direto",
                "Os Nobres 4, 5 e 6 precisam ser ativados através de compras com diamantes.",
                "Ao ser ativado com sucesso, o número de dias do nobre escolhido aumentará automaticamente em 31 dias/mês.",
                "Se você ativar mais de um nobre ao mesmo tempo, os dias restantes do nível superior serão consumidos primeiro e os dias do nível inferior do nobre permanecerão os mesmos."
            ]
        },
        {
            "title": "3. Regras de Renovação",
            "vlaue": [
                "Após a ativação bem sucedida, se você renovar sua assinatura durante o período de validade, você terá direito a um desconto de renovação. Entretanto, você não receberá um desconto de renovação se reativar após a data de expiração.",
                "激活任一等级贵族，均可获得每日签到钻石奖励，该任务需自行领取奖励，预期领取视为自动放弃"
            ]
        }
    ],
    "patricianDescriptiontitle": "Explicação de Nobres",
    "raveParty": {
        "activityIntroduction1": "🎉 Parabéns, Layla preparou uma festa de carnaval exclusiva para você. 💫 Complete tarefas de recarga e consumo para ganhar pontos correspondentes, que podem ser usados para resgatar itens da premiação! 🎁",
        "activityIntroduction2": "Além disso, os 10 melhores usuários de Ranking de Riqueza e de Contribuição receberão recompensas de privilégio extra. 🏆 Há muitas recompensas, então participe agora! 💰✨ ",
        "benefits": "Desbloqueie Recompensas Exclusivas e Benefícios",
        "consumedEvery": "Cada 16k diamantes de contribuição de presentes da sorte =20 pontos.",
        "consumptionAmounts": "Montantes de consumo",
        "consumptionList": "Ranking de Contribuição",
        "customizedAvatarFrame3": "quadro de avatar personalizado <br/>(3 dias)\"",
        "customizedGift3": "presente personalizado <br/>(3 dias)",
        "customizedLaunch15": "lançamento personalizado <br/> (15 dias) ",
        "customizedLaunch3": "Entrada personalizada <br/> (3 dias) ",
        "customizedLaunch7": "banner personalizado <br/> (7 dias) ",
        "details": "Detalhes",
        "diamonds": "Diamantes",
        "duringTheEvent": "Baseada no número de diamantes de presente da sorte consumidos durante o evento",
        "exchange": "Troca",
        "leaderboardReward": "Recompensa do Ranking",
        "leaderboardReward1": "1. Durante o evento, as recargas dos usuários serão incluídas no Ranking do evento. Os 10 melhores usuários no Ranking receberão recompensas de privilégio extra. Por favor, note que o período de validade das recompensas de presente é de 7 dias. Por favor os use dentro do período de validade.",
        "leaderboardReward2": "2. Nota: Cada 2100 diamantes recarregados=20 pontos",
        "leaderboardReward3": "3. Todas as recompensas cairão na sua conta dentro de 3 ~ 5 dias úteis após o término do evento.",
        "leaderboardReward4": "4. Para os 10 melhores no Ranking, por favor, adicione contato oficial para obter as recompensas exclusivas.",
        "luckyGiftsRules": "Regras dos presente de sorte",
        "luckyGiftsRules1": "1. Somente presentes da sorte serão incluídos na lista de contribuição ",
        "luckyGiftsRules2": "2. Envie presentes da sorte e tenha a chance de ganhar a grande recompensa.",
        "luckyGiftsRules3": "3. Quanto mais presentes da sorte são dados em grupos, maior a chance vem baú .",
        "luckyGiftsRules4": "4. A recompensa de diamantes cairá automaticamente na sua mochila.",
        "luckyGiftsRules5": "5.El múltiplo de la recompensa es el múltiplo del precio unitario del regalo. ",
        "luckyGiftsRules6": "6. Os hosts recebem desconto de 10% quando recebem os presente de sorte ",
        "luckyGiftsRules7": "7. Os presentes com um logotipo de trevo de quatro folhas",
        "luckyGiftsRulesNote": "Notas:",
        "luckyGiftsRulesNote1": "1. Os presentes de soret recebidos no Evento podem ser usados somente no Layla, é proibido outra atividade com fim lucrativo",
        "luckyGiftsRulesNote2": "2. Layla reserva-se o direito de interpretação final",
        "luckyGiftsRulesTitle": "Regras:",
        "noRanking": "Não há dados de classificação disponíveis.",
        "ok": "OK",
        "points": "Pontos",
        "points1": "} pontos",
        "pointsReward": "Regarregue recebendo pontos para trocar recompensas exclusivas! Veja as recompensas na Área de Troca abaixo! Durante o Evento, os usuários receberão pontos após atinge a meta de recarrega.Observe o período de validade das recompensas consideradas. O período de validade da recompensa começa a contar a partir do momento do resgate.Por favor, resgate antes de 3 de abril às 23:59 UTC+8 ou os pontos expiram. ",
        "pointsReward2": "Observe o período de validade das recompensas consideradas. O período de validade da recompensa começa a contar a partir do momento do resgate.",
        "pointsReward3": "Por favor, resgate antes das 23:59 UTC+8 de 6 de maio. Caso contrário, os pontos expiram.",
        "pointsRewardTitle": "Recompensa de Pontos",
        "privilege": "Privilégio",
        "rank": "Ranking",
        "rankingBased": "Baseada no número de diamantes recarregados durante o evento",
        "recharge": "Recarregar",
        "rechargeList": "Ranking de Riqueza",
        "redeemFailed": "Resgatar Falhou",
        "redeemSuccessful": "Resgatar com sucesso",
        "remainingPoints": "Pontos Restantes",
        "rewards": "Recompensas",
        "rules": "Regras",
        "telegram": "Telegrama:",
        "telegramNum": "+852 6040 9424",
        "title": "Carnaval da Primavera",
        "top1": "top1",
        "top2": "top2",
        "top3": "top3",
        "top46": "top4-6",
        "top710": "top7-10",
        "totalConsumption": "Consumo total de {",
        "tryAgain": "Pontos de resgate insuficientes, confirme e tente novamente",
        "WhatsApp": "WhatsApp:",
        "WhatsAppNumber": "+86 184 3591 9081 "
    },
    "transfer": {
        "contact_content1": "Recarga",
        "contact_content10": "Registro de transferência",
        "contact_content11": "Digite o valor",
        "contact_content12": "Meus diamantes",
        "contact_content13": "Transferência falhou, saldo de diamantes insuficiente",
        "contact_content14": "Definir senha de transferência",
        "contact_content15": "Senha de transferência",
        "contact_content16": "Por favor, digite a senha",
        "contact_content17": "Para evitar problemas com suas transferências, lembre-se desta senha",
        "contact_content18": "A senha de confirmação não corresponde à senha digitada anteriormente, por favor, tente novamente",
        "contact_content19": "Confirmar senha de transferência",
        "contact_content2": "Contato de recarga",
        "contact_content20": "Confirmar",
        "contact_content21": "Sua função de transferência está congelada, por favor, contate o administrador",
        "contact_content22": "Confirmação de transferência",
        "contact_content23": "Transferidor",
        "contact_content24": "Quantidade de transferência",
        "contact_content25": "Confirmar transferência",
        "contact_content26": "Esqueceu a senha? Contate o administrador para redefini-la",
        "contact_content27": "Senha incorreta, por favor, tente novamente",
        "contact_content28": "Transferência bem-sucedida",
        "contact_content29": "Contatos de transferência",
        "contact_content3": "Copiar",
        "contact_content30": "Não há registros de contatos de transferência",
        "contact_content31": "Não há registros de transferência",
        "contact_content32": "Sua senha de transferência foi definida com sucesso, por favor, lembre-se dela",
        "contact_content33": "Transferência",
        "contact_content34": "Detalhes dos diamantes",
        "contact_content35": "Saldo de diamantes",
        "contact_content36": "A senha deve ter pelo menos quatro dígitos",
        "contact_content4": "Iniciar transferência",
        "contact_content5": "Digite a conta do destinatário",
        "contact_content6": "Os diamantes transferidos serão creditados imediatamente na conta do destinatário e não podem ser revertidos; por favor, proceda com cautela",
        "contact_content7": "Digite o ID",
        "contact_content8": "Próximo",
        "contact_content9": "Erro de entrada de ID, usuário não existe",
        "error": "Falha na conexão de rede, por favor, tente novamente",
        "finished": "Todos os dados carregados",
        "Loading": "Carregando mais dados..."
    },
    "eventStartsIn": ""
}