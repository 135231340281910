export default{
    "xmas_title": "Merry Christmas & Happy New Year",
    "roomcup_title": "ROOM CUP",
    "roomcup_Room ID ": "Room ID",
    "roomcup_countdown": "距离本周结束",
    "roomcup_day": "天",
    "roomcup_hour": "小时",
    "roomcup_minutes": "分钟",
    "roomcup_seconds": "秒",
    "roomcup_coin_recieve": "本周已累积获得",
    "roomcup_target_icon": "Target",
    "roomcup_reward_icon": "Rewards",
    "roomcup_roomreward": "Room Rewards",
    "roomcup_roomreward_lasttarget": "上周达到的Target：",
    "roomcup_roomreward_lastreward": "上周的Reward：",
    "roomcup_claim_icon": "领取",
    "roomcup_claimed_icon": "已领取",
    "roomcup_doubletargetcontest": "Double Target Contest",
    "roomcup_ratio": "额外奖励比例：",
    "roomcup_doubletarget": "Double Target：",
    "roomcup_doubletarget_reward": "Double Target Rewards：",
    "roomcup_noroom": "您还没有创建过房间",
    "roomcup_noarea": "该地区不参与活动",
    "roomcup_rule_icon": "Rule",
    "roomcup_rule_row1": "活动数据UTC+8每周一刷新；",
    "roomcup_rule_row2": "Room Reward：每周可领取上一周达成的最高档位奖励；",
    "roomcup_rule_row3": "Double Target Rewards：当周若双倍完成上一周达成的Target，可获得额外奖励；",
    "roomcup_rule_row4": "当周若未领取奖励，则视为放弃领取，不可补领。",
    "roomcup_coins": "金币",
    "roomcup_diamonds": "钻石",
    "roomcup_notachieved": "上周未达成",
    "roomcup_successful": "领取成功",
    "budang_title": "Layla Target Explode Event",
    "budang_beforestart": "距离活动开始",
    "budang_inprogress": "距离活动结束",
    "budang_ended": "活动已结束",
    "budang_days": "天",
    "budang_hours": "小时",
    "budang_minutes ": "分钟",
    "budang_seconds": "秒",
    "budang_open": "OPEN",
    "budang_open_cannot": "您尚未获得宝箱，无法打开",
    "budang_open_once": "活动期间每天仅可开启一次",
    "budang_open_ended": "活动已结束",
    "budang_myagency": "我的公会：",
    "budang_mytarget": "我的Target：",
    "budang_distanceaway": "距离",
    "budang_distanceaway_2": "还差",
    "budang_highestlevel": "您已达到最高等级",
    "budang_rewardname": "宝箱",
    "budang_reward1": "头像框*1天",
    "budang_reward2": "入场特效*1天",
    "budang_reward3": "如有，待定",
    "budang_reward_rule": "以上奖励随机获取一个",
    "budang_rule_button": "规则",
    "budang_rule_row1": "活动时间：UTC+3 12.15 19:00 - 12.31 19:00",
    "budang_rule_row2": "我的Target：活动期间收到的总Coins. (包括活动和任务获得的金币)",
    "budang_rule_row3": "不同等级宝箱包含多种不同的奖励，每次开启宝箱可随机获得一种。宝箱每日仅可开启一次无论等级。",
    "budang_rule_row4": "每次领取的奖励道具有效期为1天。",
    "resellerNotTransaction": "该用户不属于你的可交易范围，无法完成交易",
    "tingzhan_title": "Party Glory",
    "tingzhan_time_title": "Event Time",
    "tingzhan_time": "UTC+3 11.25 19:00 - 12.4 24:00",
    "tingzhan_rule_buttom": "Rules & Rewards",
    "tingzhan_tab1": "Qualifying ",
    "tingzhan_tab1_time": "11.25-11.28",
    "tingzhan_tab1_rule_row1": "Best 16",
    "tingzhan_tab1_rule_row2": "The ranking based on the cumulative number of coins sent of the event gifts in the room, the number of coins is the same, the first to arrive first.",
    "tingzhan_tab1_rule_row3": "In this round, TOP1-8 rooms enter the gold group, and 9-16 rooms enter the silver group.",
    "tingzhan_tab1_rule_row4": "UTC+3 11.25 19:00 - 11.28 18:59",
    "tingzhan_tab_gold": "Gold Group",
    "tingzhan_tab_silver": "Silver Group",
    "tingzhan_tab2": "Promotion ",
    "tingzhan_tab2_time": "11.28-12.1",
    "tingzhan_tab2_goldrule_row1": "Best 6",
    "tingzhan_tab2_goldrule_row2": "The ranking based on the cumulative number of coins sent of the event gifts in the room, the number of coins is the same, the first to arrive first. The 7th and 8th place rooms will fall into the silver group.",
    "tingzhan_tab1_goldrule_row3": "UTC+3 11.28 19:00 - 12.01 18:59",
    "tingzhan_tab2_silverrule_row1": "Best 4",
    "tingzhan_tab2_silverrule_row2": "The ranking based on the cumulative number of coins sent of the event gifts in the room, the number of coins is the same, the first to arrive first. Rooms ranked 5-8 will be eliminated",
    "tingzhan_tab2_silverrule_row3": "UTC+3 11.28 19:00 - 12.01 18:59",
    "tingzhan_tab3": "PK ",
    "tingzhan_tab3_time": "12.1-12.4",
    "tingzhan_tab3_goldrule_row1": "Best 3-PK",
    "tingzhan_tab3_goldrule_row2": "1v1 PK with accumulated coins for 3 days. The winner advances, the loser advances to the silver group.",
    "tingzhan_tab3_goldrule_row3": "There is a quota for resurrection in this round, and the loser with the most gold coins will be resurrected.",
    "tingzhan_tab3_goldrule_row4": "UTC+3 12.01 19:00 - 12.04 18:59",
    "tingzhan_tab3_silverrule_row1": "Best 3-PK",
    "tingzhan_tab3_silverrule_row2": "1v1PK with accumulated coins for 3 days. The loser will be eliminated, the number of coins is the same, the first to arrive first.",
    "tingzhan_tab3_silverrule_row3": "UTC+3 12.01 19:00 - 12.04 18:59",
    "tingzhan_tab4": "Final ",
    "tingzhan_tab4_time": "12.4-12.5",
    "tingzhan_tab4_rule_row1": "Final",
    "tingzhan_tab4_rule_row2": "The ranking based on the cumulative number of coins sent of the event gifts in the room, the number of coins is the same, the first to arrive first.",
    "tingzhan_tab4_rule_row3": "UTC+3 12.04 19:00 - 12.05 18:59",
    "tingzhan_tab4_countdown": "Final Countdown",
    "tingzhan_danger": "Danger",
    "tingzhan_win": "Win",
    "tingzhan_out": "Out",
    "tingzhan_Revive": "Revive",
    "tingzhan_Silver": "Silver",
    "tingzhan_rule_back": "Back",
    "tingzhan_rule_tab1": "Event Intro",
    "tingzhan_rule_tab2": "Rewards",
    "tingzhan_rule_tab1_title": "Event Gifts",
    "tingzhan_rule_luvkygift1": "?",
    "tingzhan_rule_gift2": "Rock Kitty",
    "tingzhan_rule_gift3": "Falconer",
    "tingzhan_rule_gift4": "Treasure Map",
    "tingzhan_rule_gift4_name": "Sponsorship (TOP1) * 10 days",
    "tingzhan_gift_price": "Price",
    "tingzhan_tab2_banner_title": "1.5 Times Exp.!",
    "tingzhan_tab2_banner_body": "Sending event gifts during the event and you will get 1.5 Times experience points.",
    "tingzhan_tab2_roomreward": "Room Rewards",
    "tingzhan_tab2_supporterreward": "Supporter Rewards",
    "tingzhan_tab2_reward": "Rewards",
    "tingzhan_tab2_roombg": "Moving Bg",
    "tingzhan_roomcover": "Room Cover",
    "tingzhan_1stplace": "1st Place",
    "tingzhan_2ndplace": "2nd Place",
    "tingzhan_3rdplace": "3rd Place",
    "tingzhan_tab2_usd": "USD",
    "tingzhan_500usd": "500 USD",
    "tingzhan_400usd": "400 USD",
    "tingzhan_300usd": "300 USD",
    "tingzhan_200usd": "200 USD",
    "tingzhan_100usd": "100 USD",
    "tingzhan_tab2_avatar": "Avatar Frame",
    "tingzhan_tab2_entrance": "Entrance Effect",
    "tingzhan_tab2_specialid": "Special ID",
    "tingzhan_tab2_sponsorship": "Event Gift Sponsorship",
    "tingzhan_supporter_1stplace": "1st Place Room Supporter",
    "tingzhan_supporter_2ndplace": "2nd Place Room Supporters",
    "tingzhan_supporter_3rdplace": "3rd Place Room Supporters",
    "tingzhan_30days": "30 days",
    "tingzhan_15days": "15 days",
    "tingzhan_7days": "7 days",
    "tingzhan_3days": "3 days",
    "tingzhan_tab2_specialid_body1": "Random 2/3/4 -digital ID",
    "tingzhan_tab2_specialid_body2": "Special ID: 50/500/5000",
    "tingzhan_tab2_sponsorship_body": "Sponsorship (TOP1) * 10 days",
    "tingzhan_tab2_other": "Aside from experience points, event rewards will be sent in 3 working days to the user's backpack.",
    "tingzhan_notstart": "Event hasn't started",
    "turkeyCumhuriyet": "Cumhuriyet Bayramı Event",
    "turkeyDistance": "距离活动开始",
    "turkeyStart": "距离活动结束",
    "turkeyEnd": "活动已结束",
    "turkeyEventGifts": "活动礼物",
    "turkeyCumhuriyetGift": "Cumhuriyet Bayramı",
    "turkey80": "80k coins",
    "turkeyDays": "days",
    "turkeyHours": "hours",
    "turkeyMin": "min",
    "turkeyToStart": "to Start",
    "turkeyTurkey": "Turkey",
    "turkeyTop1": "Top 1 ",
    "turkey500": 500,
    "turkeyTop2_3": "Top2-3 ",
    "turkey300": "300k diamonds",
    "turkeyTop4_10": "Top4-10",
    "turkey100": " 100k diamonds",
    "turkey200": 200,
    "turkey200k": "200k coins",
    "turkeyTop4_10_2": "Top 20 rooms",
    "turkeySupporters": "Supporters",
    "turkeyAnchors": "Anchors",
    "turkeyParties": "Parties",
    "turkeyRanking": "Ranking countries: Turkey & Azerbaijan",
    "turkeyRanking2": "Ranking countries: Non - Turkey or Azerbaijan",
    "turkeytop1newanchor": "200k coins",
    "turkeytop2-3newanchor": "50k coins",
    "turkeytop4-10newanchor": "20k coins",
    "turkeytop1newsupport": "Noble 6*30 days",
    "turkeytop2-3newsupport": "Noble 5*30 days",
    "turkeytop4-10newsupport": "Noble 4*30 days",
    "wealthNeedNextLevel": "经验即可升级至下一等级",
    "pkTouramentPro": "PK Tourament Pro",
    "pkTop1": "Top1",
    "pkTop2": "Top2",
    "pkTop3": "Top3",
    "pk1000Usd": "1000 USD",
    "pk500Usd": "500 USD",
    "pk300Usd": "300 USD",
    "pkRewards": "Rewards for each Qualifying Round",
    "pkRewards2": "Rewards for each Promotion Round",
    "pk50kCoins": "50k Coins Target",
    "pk100kCoins": "100k Coins Target",
    "pkNote": "Note: for qualifying round and promotion round rewards, if the diamonds spent in the specific PK is less than the reward amount. The reward will not be sent but the PK result is still counted",
    "pk200Usd": "200 USD",
    "pk30Day": "Frame*30 Days",
    "pk15Day": "Frame*15 Days",
    "pk7Day": "Frame*7 Days",
    "pkNote2": "Note：Support in all PK Rounds is calculated. Lucky gifts are counted 10% Treasure Boxes are counted based on the gift won amount. ",
    "pkTourament": "Tourament",
    "pkToday": "Today Schedule",
    "pkSupporter": "Supporter",
    "pkQualifying": "Qualifying Round",
    "pkQualifying1": "Qualifying Round1",
    "pkQualifying2": "Qualifying Round2",
    "pkPromotion": "Promotion Round",
    "pkSummit": "Summit Series",
    "pk32Finals": "1/32-Finals",
    "pk16Finals": "1/16-Finals",
    "pk8Finals": "1/8-Finals",
    "pkQuater": "Quater-Finals",
    "pkSemi": "Semi-Finals",
    "pkFinal": "Final",
    "pk3rd": "3rd Place",
    "pk_host_reward_top1": "7 Days",
    "pk_host_reward_top1_b": "50,000 Points",
    "pk_host_reward_top2": "3 Days",
    "pk_host_reward_top2_b": "30,000 Points",
    "pk_host_reward_top3": "1 Days",
    "pk_host_reward_top3_b": "20,000 Points",
    "pk_supporter_reward_top1": "7 Days",
    "pk_supporter_reward_top1_b": "50,000 Diamond",
    "pk_supporter_reward_top2": "3 Days",
    "pk_supporter_reward_top2_b": "30,000 Diamond",
    "pk_supporter_reward_top3": "1 Days",
    "pk_supporter_reward_top3_b": "20,000 Diamond",
    "pk_host_rule_row1": "Support your favorite streamer by sending gifts to help them advance to the next round!  ",
    "pk_host_rule_row2": "Engage in the event for a chance to win exclusive rewards!  ",
    "pk_host_rule_note": "For the TOP Three streamers and users, please add our contact information to get Rewards. Our WhatsApp number：+86 184 3591 9081 Telegram: +852 6040 9424",
    "pk_supporter_rule_note": "For the TOP Three streamers and users, please add our contact information to get Rewards. Our WhatsApp number：+86 184 3591 9081 Telegram: +852 6040 9424",
    "dreamMidsummer": "仲夏夜之梦",
    "dreamSend": "送专属礼物，定仲夏之情\n",
    "dreamRank": "活动期间赠送以下活动礼物，排名越高奖品越丰厚，快来参与获得丰厚奖励吧！",
    "dreamPreview": "奖励预览",
    "dreamSupporter": "Supporter",
    "dreamHost": "Host",
    "dreamTop1": "第一名",
    "dreamTop2": "第二名",
    "dreamTop3": "第三名",
    "dreamTop4_10": "第4-10名",
    "dreamRankings": "排行榜",
    "dreamRose": "玫瑰皇冠",
    "dreamRoseBasket": "玫瑰花篮",
    "dreamButterfly": "蝴蝶仙境",
    "dreamLovers": "伊斯兰情侣",
    "dreamFlower": "花朵",
    "dream15d": "*15d",
    "dreamCentury": "辉煌世纪",
    "dream7": "*7",
    "dream7d": "*7d",
    "dreamHeart": "心动旅程",
    "dreamSummerLove": "夏日之恋",
    "dreamFancy": "独角兽入场",
    "dreamRules": "活动规则",
    "dreamTime": "活动时间",
    "dreamPlay": "活动玩法",
    "dreamUsersTop": "在活动期间赠送活动礼物，根据送礼钻石数和收礼金币数进行排名，排名靠前用户可以获得奖励。",
    "dreamRewards": "玫瑰花篮和蝴蝶仙境榜单奖励",
    "dreamFrame": "玫瑰皇冠头框",
    "dreamCenturyGift": "辉煌世纪礼物",
    "dreamEntrance": "心动旅程入场",
    "dream5": "*5",
    "dream5d": "*5d",
    "dream3": "*3",
    "dream3d": "*3d",
    "dreamDiamonds": "钻石",
    "dream25000": "*25000",
    "dreamVerification": "夏日之恋铭牌",
    "dreamUnicorn": "独角兽入场",
    "dreamEverlasting": "伊斯兰情侣和花朵榜单奖励",
    "dreamCoins": "金币",
    "dream100000": "*100000",
    "dream50000": "*50000",
    "dream5000": "*5000",
    "dreamHostBottom": "金币计算在目标里",
    "zainCashTitle": "Zain Cash",
    "zainTotalPay": "总金额",
    "zainIqd": "IQD",
    "zainName": "姓名",
    "zainNameTips": "请输入你ID身份证上的姓名",
    "zainPhoneNumber": "电话号码",
    "zainPhoneNumberTips": "请输入你的电话号码",
    "zainSubmit": "支付",
    "zainNumberErrorTips": "请输入数字或者+号",
    "zainNameNotNull": "姓名不能为空",
    "luckyEventStartsIn": "活动开始于",
    "luckyEventEndsIn": "",
    "luckyEventInstruction": "活动说明",
    "luckySendingFollowing": "赠送下列礼物可以获得小绵羊",
    "luckyRoom": "房间",
    "luckyRoomTips": "",
    "luckyGifter": "送礼人",
    "luckyAnchors": "",
    "luckyGoldenBoot": "",
    "luckyFootball": "",
    "luckyDefensiveMaster": "",
    "luckyTheWorldCup": "",
    "luckyNoOne": "列表为空~",
    "luckyRules": "规则",
    "luckyHowTo": "如何进入排行榜?",
    "luckyHowToGetI": "如何获得",
    "luckyTheGifts": "活动赠送的礼物按照钻石累计数量排名，活动领取的礼物按照金币累计数量排名。",
    "luckyRewards": "奖励",
    "luckyRoomReward": "房间奖励",
    "luckyGifterRewards": "送礼人奖励",
    "luckyBlessingCard": "祈祷卡",
    "luckyTop_1": "积分 <span>100000</span>",
    "luckyTop_2": "头像框x<span>10<span>天",
    "luckyTop_3": "座驾 x<span>10</span>天",
    "luckyTop_1_1": "",
    "luckyTop_2_1": "",
    "luckyTop_3_1": "",
    "luckyTop_4": "专属横幅",
    "luckyEventEnd": "活动结束",
    "anchorActive": {
        "activeList": "活跃榜",
        "activeListRewards": "活跃榜奖励：",
        "back": "返回",
        "banner10": "10天定制Banner",
        "capture": "成为焦点，赢得奖励",
        "charming": "魅力榜奖励：",
        "charmingList": "魅力榜",
        "days": "日",
        "entrance10": "10天活动专属座驾",
        "entrance5": "5天活动专属座驾",
        "entrance7": "7天活动专属座驾",
        "frame10": "10天活动专属头像框",
        "frame5": "5天活动专属头像框",
        "frame7": "7天活动专属头像框",
        "hours": "时",
        "laseWeek": "上周",
        "laseWeekRank": "上周榜单",
        "listOne": "榜单一：活跃榜",
        "listOneMsg1": "主播通过1v1获得的points收入=相应活跃值(包含主播回消息，语音视频通话等，除收礼以外的所有积分)",
        "listOneMsg2": "语音房上麦时长/分钟=10积分",
        "listTwo": "榜单二：魅力榜",
        "listTwoMsg1": "1v1收礼钻石数量=相应魅力值",
        "listTwoMsg2": "语音房收礼钻石数量=双倍魅力值",
        "listTwoMsg3": "幸运礼物10%折算魅力值",
        "minutes": "分",
        "noRanking": "暂无榜单数据",
        "points100000": "10k积分",
        "points30k": "30k积分",
        "points50000": "5k积分",
        "points50k": "50k积分",
        "rank": "榜单",
        "rewards": "奖励",
        "rules": "规则",
        "seconds": "秒",
        "top1": "TOP1",
        "top2": "TOP2",
        "top3": "TOP3"
    },
    "bindAccount": {
        "Bank_Transfer": "银行转账",
        "Bind_Account": "绑定账号",
        "limit": {
            "account_name_tips": "用英文填写全名",
            "bank_card_number": "填写银行卡号，例如：10000000000",
            "bankCode": "填写银行Swift Code，例如：CMBCCNBS",
            "bankName": "填写银行英文名称，例如：State Bank of India",
            "City": "用英语填写你的城市",
            "country_code": "填写大写英文国家代码，例如：IN",
            "name_tips": "用英文填写名字",
            "payoneer_email": "请在payoneer中找到你绑定的邮箱",
            "payoneerID": "请在你的Payoneer账户中找到你的ID，例如：47289645466",
            "paypal_email": "输入绑定Paypal账户的邮箱",
            "State_or_Province": "用英文填写州或省",
            "Street": "用英文填写街道",
            "StrePostal_Codeet": "填写地址的邮政编码"
        },
        "Payment_Method": "收款方式",
        "placeholder": {
            "bank_account_name": "收款人银行账户名称",
            "bank_card_number": "收款银行账号",
            "bankCode": "收款银行代码",
            "bankName": "收款银行英文名称",
            "Beneficiary_country_code": "收款人国家代码",
            "City": "收款人地址 - 城市",
            "country": "银行卡国家代码",
            "Payee_First_name": "收款人名",
            "Payee_Last_Name": "收款人姓",
            "payoneer_email": "填写你的邮箱",
            "payoneerID": "填写你的Payoneer ID",
            "paypal_email": "填写你的账号邮箱",
            "Postal_Code": "收款人地址 - 邮政编码",
            "State_or_Province": "收款人地址 - 州或省",
            "Street": "街道"
        },
        "popup": {
            "Bound_account": "绑定账户：",
            "cancel": "取消",
            "complete": "完成",
            "confirm": "确认",
            "country": "银行卡所在国家",
            "payment_method": "收款方式",
            "payoneer_email": "Payoneer账号邮箱"
        },
        "title": {
            "bank_account_name": "收款人的银行账户名称",
            "bank_card_number": "收款人银行卡号",
            "bankCode": "银行卡国家代码",
            "bankName": "收款银行名称",
            "country": "银行卡所在国家",
            "Payee_Address": "收款人地址",
            "Payee_First_name": "收款人名",
            "Payee_Last_Name": "收款人姓",
            "payoneer_email": "Payoneer账号邮箱",
            "payoneerID": "Payoneer ID",
            "paypal_email": "Paypal账号邮箱"
        },
        "toast": {
            "bank_account_name": "收款人的银行账户名称不能为空",
            "bank_card_number": "收款人银行卡号不能为空",
            "bankCode": "收款银行代码不能为空",
            "bankName": "收款银行名称不能为空",
            "Beneficiary_country_code": "收款人地址国家不能为空",
            "City": "收款人地址城市不能为空",
            "country": "银行卡所在国家不能为空",
            "emailyz": "请输入有效且合法的电子邮件地址！",
            "Payee_First_name": "收款人名不能为空",
            "Payee_Last_Name": "收款人姓不能为空",
            "payoneer_account": "请再次检查确认，以免造成财产损失",
            "payoneer_email": "Payoneer邮箱不能为空",
            "payoneerID": "Payoneer ID 不能为空",
            "paypal_email": "Payoneer 邮箱 不能为空",
            "State_or_Province": "收款人地址州或省不能为空",
            "Street": "收款人地址街道不能为空"
        }
    },
    "diamondDescription": {
        "title": "SUB&PSUB规则",
        "txt1": "1. SUB和PSUB可以同时开启；",
        "txt2": "2. SUB用户每日可领取免费消息券；",
        "txt3": "3. PSUB用户每日可领取免费钻石；",
        "txt4": "4. PSUB用户每日可完成返钻任务；",
        "txt5": "5. PSUB返钻任务每日更新需当日领取，SUB每日消息券，需要当日领取，过期不累计；",
        "txt6": "6. 游戏的消费将不计入消费钻石数量。"
    },
    "eventRewards": {
        "player": "当日活跃玩家",
        "proportion": "返奖比例",
        "title": "活动返奖",
        "txt1": "1. 每天0点(UTC+8)计算昨日的数据。",
        "txt2": "2. 根据房间内参与的人数和房间内实际消耗的钻石（用户下注钻石-用户获得钻石）进行返奖。",
        "txt3": "3. 每周一0点(UTC+8)计算上周数据，奖励将在3日内下发。",
        "txt4": "4. 返奖比例：",
        "txt5": "5. 活跃玩家定义：当日累计下注超过1000钻。"
    },
    "failed": "操作失败！",
    "idolHelp": {
        "content1": "Layla Idol是Layla App给受欢迎的女孩颁发的特殊标识。成为Layla Idol将获得多种特权。包括特殊的标识，更多的曝光，更多的匹配消息。",
        "content2_1": "1.首先要通过真人认证的审核，其次，聊天分要达到80分",
        "content2_2": "2. 当日达到活跃条件，聊天分会在次日4点(UTC+8)根据过去7日的数据计算聊天分。",
        "content2_3": "3. 当日未达到活跃条件，次日聊天分将归0。再次活跃将重新累积计算聊天分。",
        "content2_4": "4. 当日的活跃条件为：",
        "content2_4_1": "a.过去7日在线时长>",
        "content2_4_2": "b.过去7日主动聊天人数>",
        "content2_4_3": "c.昨日登录过App。",
        "content3_1": "聊天分是根据你在私聊场景的受欢迎程度计算出来的。这包括：",
        "content3_2": "1. 曝光。有多少人在首页、动态、资料页看到过你。",
        "content3_3": "2. 在线时长。你在App内待了多少时长。",
        "content3_4": "3. 活跃亲密度数。当日有多少亲密度大于3的用户给你发送过信息。",
        "content3_5": "4. 消息被回复率。你发出消息，有多少被回复了。",
        "content3_6": "5. 主动聊天人数。你给多少个用户打招呼或者发消息。",
        "content3_7": "6. 有效回复率。你在有效时间内回复他人消息的比率。",
        "content3_8": "7. 聊天人均耗钻。和你聊天的人消耗了多少钻石，包括聊天和1v1的送礼。",
        "content3_9": "8. 聊天耗钻人数。有多少人在和你聊天时消耗过钻石，包括聊天和1v1送礼。",
        "content4_1": "你可以在聊天分详情页，看到你各项数据的统计值，以及和Layla Idol的对比情况。",
        "content4_2": "点击对应项目的提示，会有提升对应数值的引导。",
        "header1": "什么是Layla Idol",
        "header2": "如何成为Layla Idol",
        "header3": "什么是聊天分",
        "header4": "如何提高聊天分",
        "minute": "分钟。",
        "people": "人。",
        "title": "Layla Idol Help"
    },
    "inviteCash": {
        "COPY": "复制",
        "Copy_successfully": "复制成功",
        "title": "邀请赚金"
    },
    "Level": {
        "avatarFrame": "头像框",
        "badgeReward": "勋章奖励",
        "charmLevel": "魅力等级",
        "currentExp": "当前经验值：",
        "entranceEffect": "入场座驾",
        "familyLevel": "家族等级",
        "familyLeveldata": [
            "等级",
            "所需贡献值",
            "成员人数",
            "身份"
        ],
        "gradePrivileges": "等级特权",
        "identity": [
            "副族长",
            "长老"
        ],
        "personLevel": "个人等级",
        "stillNeed": "升级需要",
        "title": "等级",
        "upgrade": "怎样升级？",
        "upgradebox": {
            "charm": [
                {
                    "title": "收礼",
                    "value": "1金币 = 1魅力值"
                }
            ],
            "family": [
                {
                    "title": "家族任务",
                    "value": "领取家族贡献值"
                }
            ],
            "person": [
                {
                    "title": "送礼",
                    "value": "1钻石 = 1个人经验"
                },
                {
                    "title": "完成任务",
                    "value": "领取个人经验"
                }
            ],
            "wealth": [
                {
                    "title": "送礼",
                    "value": "1钻石 = 1财富经验"
                },
                {
                    "title": "完成任务",
                    "value": "领取财富经验"
                }
            ]
        },
        "wealthLevel": "财富等级"
    },
    "nation": {
        "ae": "阿拉伯联合酋长国",
        "ao": "安哥拉",
        "ar": "阿根廷",
        "at": "奥地利",
        "au": "澳大利亚",
        "bd": "孟加拉国",
        "bh": "巴林",
        "br": "巴西",
        "ca": "加拿大",
        "cl": "智利",
        "cn": "中国大陆",
        "de": "德国",
        "dz": "阿尔及利亚",
        "eg": "埃及",
        "es": "西班牙",
        "fr": "法国",
        "gb": "英国",
        "hk": "中国香港",
        "il": "以色列",
        "iq": "伊拉克",
        "ir": "伊朗",
        "it": "意大利",
        "jo": "约旦",
        "jp": "日本",
        "kw": "科威特",
        "lb": "黎巴嫩",
        "lv": "拉脱维亚",
        "ly": "利比亚",
        "ma": "摩洛哥",
        "mx": "墨西哥",
        "my": "马来西亚",
        "nl": "荷兰",
        "om": "阿曼",
        "ph": "菲律宾",
        "pk": "巴基斯坦",
        "ps": "巴勒斯坦",
        "pt": "葡萄牙",
        "qa": "卡塔尔",
        "ru": "俄罗斯",
        "sa": "沙特阿拉伯",
        "sd": "苏丹",
        "sy": "叙利亚",
        "tn": "突尼斯",
        "tr": "土耳其",
        "tw": "台湾",
        "us": "美国",
        "ye": "也门"
    },
    "nobilityLevel": {
        "diamond": "钻石消耗",
        "head": "贵族等级",
        "title": "贵族等级规则",
        "txt1": "1. 每个自然月1号0点开始（UTC+8），系统将自动统计用户经验值，根据下列表格经验值重新匹配用户的贵族等级。",
        "txt2": "2. 每充值1钻石=1经验值。",
        "txt3": "3. 经验值计算=当前月经验值+上月经验值。",
        "txt4": "Ⅰ. 用户在获得对应贵族等级后，贵族等级和特权会保留至下个月月末，例如：10月17日用回达到Lv.4，则Lv.4等级会延续至11月30日",
        "txt5": "Ⅱ. 在当前自然月周期内，若用户在获得较低的贵族等级后，再达到更高的等级，则直接获得更高级别的贵族等级，相应的贵族等级会保留至下个月月末，例如：在10月17日用户在达到Lv.2后，在10月19日VIP等级达到Lv.4，则直接获得Lv.4，并且等级延续至11月30日。",
        "txt6": "Ⅲ. 当前自然月结束后，VIP等级可以保留上一个月获得的，但下个月再次从零开始累计VIP值，例如：用户10月达到Lv.4等级，11月也保留Lv.4等级，并且在12月结束前只达到Lv.2，则从12月1日开始只享受Lv.2等级和特权。",
        "txt7": "等级",
        "txt8": "所需经验值",
        "txt9": "上一级到此级所需经验值"
    },
    "patricianDescription": [
        {
            "title": "1、什么是贵族",
            "vlaue": [
                "贵族是平台上用户尊贵身份的象征，目前分为六个等级。",
                "不同等级的贵族有相应的特权，每月付费激活即可获得贵族并享受特权。"
            ]
        },
        {
            "title": "2、购买规则",
            "vlaue": [
                "获得贵族1、2、3的特权，直接支付即可激活。",
                "贵族4、5、6需要用钻石购买激活。",
                "激活成功后，所选贵族天数将自动增加31天/月。",
                "若同时激活多个不同等级的贵族，则优先消耗较高等级的剩余天数，低等级贵族的天数保持不变。"
            ]
        },
        {
            "title": "3、续订规则",
            "vlaue": [
                "激活成功后，如果你在有效期内续费，你可以享受续费折扣。但你在有效期后重新激活，您将不会享受续订折扣。",
                "激活任一等级贵族，均可获得每日签到钻石奖励，该任务需自行领取奖励，预期领取视为自动放弃。"
            ]
        }
    ],
    "patricianDescriptiontitle": "贵族说明",
    "raveParty": {
        "activityIntroduction1": " 🎉恭喜你，蕾拉为你准备了一场专属狂欢派对。💫完成充值任务和消费任务即可获得相应积分，可用于从奖池兑换物品！🎁",
        "activityIntroduction2": "此外，充值次数前10名的用户和消费次数前10的用户将获得额外的特权奖励。🏆有很多奖励，所以赶紧加入吧！💰✨",
        "benefits": "解锁专属奖励和特权",
        "consumedEvery": "每消费16000颗钻石的幸运礼物将获得20分。",
        "consumptionAmounts": "消耗金额",
        "consumptionList": "消耗榜",
        "customizedAvatarFrame3": "customized avatar frame <br/>(3 days)",
        "customizedGift3": "customized gift <br/> (3 days)",
        "customizedLaunch15": "customized launch <br/> (15 days) ",
        "customizedLaunch3": "customized entrance <br/>(3 days) ",
        "customizedLaunch7": "customized banner <br/> (7 days) ",
        "details": "详情",
        "diamonds": "钻石",
        "duringTheEvent": "根据活动期间消耗的幸运礼物钻石数量进行排名",
        "exchange": "兑换",
        "leaderboardReward": "排行榜奖励",
        "leaderboardReward1": "1.活动期间，用户充值将计入活动排行榜。充值排行榜和消费排行榜前10名用户将获得额外的特权奖励。请注意，礼品奖励的有效期为7天。请在有效期内使用。",
        "leaderboardReward2": "2.请注意：每充值2100颗钻石可获得20分",
        "leaderboardReward3": "3.所有奖励将在活动结束后3~5个工作日内送达您的账户。",
        "leaderboardReward4": "4.对于两个排行榜的前十名，请添加我们的联系方式领取专属奖励",
        "luckyGiftsRules": "幸运礼物规则",
        "luckyGiftsRules1": "1.只有幸运礼物才会被列入消耗榜",
        "luckyGiftsRules2": "2.送上幸运礼物，有机会赢取大奖。",
        "luckyGiftsRules3": "3.成组送出的幸运礼物越多，赢得头奖的机会就越大。",
        "luckyGiftsRules4": "4.奖品是钻石，会被送到你的包里。",
        "luckyGiftsRules5": "5.奖励的倍数是礼物单价的倍数。",
        "luckyGiftsRules6": "6.主播在收到幸运礼物时，只会收到礼物价值的10%。",
        "luckyGiftsRules7": "7.右上角有四叶草标志的礼物是幸运礼物",
        "luckyGiftsRulesNote": "笔记：",
        "luckyGiftsRulesNote1": "1.用户在幸运礼物活动中获得的奖励只能在Layla平台上使用，不得用于任何营利活动。",
        "luckyGiftsRulesNote2": "2.Layla保留最终解释权",
        "luckyGiftsRulesTitle": "规则：",
        "noRanking": "暂无榜单数据",
        "ok": "确定",
        "points": "积分",
        "points1": ")积分兑换",
        "pointsReward": "通过充值获得积分以兑换独家奖励！在下面的兑换区查看奖励！在活动期间，如果累计充值达到相应的钻石数量，将获得积分。",
        "pointsReward2": "请注意奖励的有效期。奖励的有效期从赎回的那一刻开始计算。",
        "pointsReward3": "请在5月6日23:59 UTC+8之前兑换。否则，积分将过期。",
        "pointsRewardTitle": "积分奖励",
        "privilege": "特权",
        "rank": "排行",
        "rankingBased": "根据活动期间充值的钻石数量进行排名",
        "recharge": "充值",
        "rechargeList": "充值榜",
        "redeemFailed": "兑换失败",
        "redeemSuccessful": "兑换成功",
        "remainingPoints": "剩余积分",
        "rewards": "奖励",
        "rules": "规则",
        "telegram": "Telegram:",
        "telegramNum": "+852 6040 9424",
        "title": "春季狂欢",
        "top1": "top1",
        "top2": "top2",
        "top3": "top3",
        "top46": "top4-6",
        "top710": "top7-10",
        "totalConsumption": "共消耗(",
        "tryAgain": "兑换积分不足,请确认后重试",
        "WhatsApp": "WhatsApp： ",
        "WhatsAppNumber": "+86 184 3591 9081"
    },
    "transfer": {
        "contact_content1": "充值",
        "contact_content10": "转账记录",
        "contact_content11": "输入数量",
        "contact_content12": "我的钻石",
        "contact_content13": "转账失败，你的钻石余额不足",
        "contact_content14": "设置转账密码",
        "contact_content15": "转账密码",
        "contact_content16": "请输入密码",
        "contact_content17": "为防止影响你的转账操作，请牢记该密码",
        "contact_content18": "确认密码和上一步输入密码不一致，请重试",
        "contact_content19": "确认转账密码",
        "contact_content2": "充值联系人",
        "contact_content20": "确认",
        "contact_content21": "你的转账功能被冻结，请联系管理员沟通",
        "contact_content22": "转账确认",
        "contact_content23": "转账人",
        "contact_content24": "转账数量",
        "contact_content25": "确认转账",
        "contact_content26": "忘记密码？联系管理员重置",
        "contact_content27": "密码错误，请重新尝试",
        "contact_content28": "转账成功",
        "contact_content29": "转账通讯录",
        "contact_content3": "复制",
        "contact_content30": "没有转账通讯记录",
        "contact_content31": "没有转账记录",
        "contact_content32": "你的转账密码设置成功，请牢记在心",
        "contact_content33": "转账",
        "contact_content34": "钻石明细",
        "contact_content35": "钻石余额",
        "contact_content36": "密码不能少于四位数",
        "contact_content4": "发起转账",
        "contact_content5": "输入对方账号",
        "contact_content6": "转移的钻石会立即到账对方账户，无法退回；请谨慎操作",
        "contact_content7": "输入ID",
        "contact_content8": "下一步",
        "contact_content9": "ID输入错误，该用户不存在",
        "error": "网络连接失败，请重试",
        "finished": "已经全部加载完毕",
        "Loading": "正在加载更多的数据..."
    },
    "eventStartsIn": ""
}